import { http } from '@kaaylabs/kaaylabs-http-package';
import { getApiUrl } from './environment';

const baseUrl = getApiUrl();

// user URLS
export const getUser = (parameters) => http.get(`${baseUrl}/user`, parameters);
export const getCurrentUser = (userID) =>
  http.get(`${baseUrl}/user/get-user`, userID);
export const userVerification = (parameters) =>
  http.get(`${baseUrl}/user/user-verification`, parameters);
export const deleteUser = (userID) => http.del(`${baseUrl}/user/${userID}`);
export const createUser = (details) => http.post(`${baseUrl}/user/`, details);
export const editUser = (parameters, userID) =>
  http.put(`${baseUrl}/user/${userID}`, parameters);
export const userAuthenticate = (parameters) =>
  http.post(`${baseUrl}/user/login`, parameters);
export const getRole = () => http.get(`${baseUrl}/role`);
export const sendPass = (parameters) =>
  http.post(`${baseUrl}/user/forgetpassword`, parameters);
export const resetPass = (parameters) =>
  http.put(`${baseUrl}/user/reset/Password`, parameters);
// Company Key URLS
export const getCompanyKey = (parameters) =>
  http.get(`${baseUrl}/company-key`, parameters);
export const getCompanyDetails = (parameters) =>
  http.get(`${baseUrl}/company-info`, parameters);
export const deleteCompanyKey = (keyID) =>
  http.del(`${baseUrl}/company-key/${keyID}`);
export const createCompanyKey = (details) =>
  http.post(`${baseUrl}/company-key/`, details);
export const editCompanyKey = (parameters, keyID) =>
  http.put(`${baseUrl}/company-key/${keyID}`, parameters);

// API Key URLS
export const getAPIKeyByUser = (ID) => http.get(`${baseUrl}/api-key/${ID}`);
export const editAPIKey = (parameters, ID) =>
  http.put(`${baseUrl}/apiKey/${ID}`, parameters);
export const addAPIKey = (details) => http.post(`${baseUrl}/api-key/`, details);
// Parts
export const getPartsByUser = (ID) => http.get(`${baseUrl}/parts/${ID}`);
export const getPartsFileByID = (ID) => http.get(`${baseUrl}/parts/file/${ID}`);
export const addPartNumberUpload = (details) =>
  http.post(`${baseUrl}/parts/`, details);
export const refreshSearchData = (ID) => http.put(`${baseUrl}/parts/${ID}`);
export const deleteSearchFile = (keyID) =>
  http.del(`${baseUrl}/parts/${keyID}`);
// Order
export const addQuote = (details) => http.post(`${baseUrl}/quote/`, details);
export const getQuotebyUser = (ID) => http.get(`${baseUrl}/quote/${ID}`);
export const getQuoteFileByID = (ID) => http.get(`${baseUrl}/quote/file/${ID}`);
export const deleteQuoteFile = (keyID) => http.del(`${baseUrl}/quote/${keyID}`);

// LT
export const addLTUpload = (details) => http.post(`${baseUrl}/lt/`, details);
export const getLTbyUser = (ID) => http.get(`${baseUrl}/lt/${ID}`);
export const getLtFileByID = (ID) => http.get(`${baseUrl}/lt/file/${ID}`);
export const refreshLeadTimeData = (ID) => http.put(`${baseUrl}/lt/${ID}`);
export const deleteLTFile = (keyID) => http.del(`${baseUrl}/lt/${keyID}`);

// Notification
export const getNotification = (ID) =>
  http.get(`${baseUrl}/notification/${ID}`);
export const closeNotification = (ID) =>
  http.post(`${baseUrl}/notification/${ID}`);
