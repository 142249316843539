import React from 'react';
import './loader.less';

const Loader = () => {
  return (
    <div className="loader">
      <div className="ripple">
        <div />
        <div />
      </div>
    </div>
  );
};
export default Loader;
