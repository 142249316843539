import React, { useContext, useState, lazy, Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  USERINFO,
  USERINFOID,
  FORGETPASSWORD,
  GENERATEPASSWORD,
  COMPANYKEYINFO,
  PERMISSIONERROR,
  ASNKEYINFO,
  SEARCHINFO,
  QUOTEINFO,
  LTINFO,
} from './settings/constants';
import './style/zp-theme/index.less';
import 'font-awesome/css/font-awesome.min.css';
import AuthProvider, { AuthContext } from './context/auth';
import permissionError from './containers/error-pages/error-403';
import Loader from './components/inline-loader';

const NotFoundPage = lazy(() => import('./containers/error-pages/error-404'));
const UserLayout = lazy(() =>
  import('./containers/user-management/user-layout')
);
const AppUserLayout = lazy(() =>
  import('./containers/user-management/user-details')
);
const KeyManagement = lazy(() => import('./containers/company-key-management'));
const SearchInfo = lazy(() => import('./containers/search-api'));
const ASNInfo = lazy(() => import('./containers/ASN'));
const QuoteInfo = lazy(() => import('./containers/Quotes'));
const LeadTimeInfo = lazy(() => import('./containers/LT'));

const GeneratePassword = lazy(() =>
  import('./containers/authentication/generate-password')
);
const ForgetPassword = lazy(() =>
  import('./containers/authentication/forgot-password')
);
const Login = lazy(() => import('./containers/authentication/login-container'));
const AdminLayout = lazy(() => import('./containers/layout/layout'));

function PrivateRoute({ component: Component, layout: AppLayout, ...rest }) {
  const [state, setState] = useState({});
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(properties) => {
        if (!localStorage.getItem('token') && !isAuthenticated)
          history.push('/');
        return (
          <Suspense fallback={<Loader />}>
            <AppLayout {...properties} quote={setState}>
              <Component {...properties} quote={state} />
            </AppLayout>
          </Suspense>
        );
      }}
    />
  );
}

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute
            exact
            path={USERINFO}
            component={UserLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={USERINFOID}
            component={AppUserLayout}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={COMPANYKEYINFO}
            component={KeyManagement}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={SEARCHINFO}
            component={SearchInfo}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={QUOTEINFO}
            component={QuoteInfo}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={LTINFO}
            component={LeadTimeInfo}
            layout={AdminLayout}
          />
          <PrivateRoute
            exact
            path={ASNKEYINFO}
            component={ASNInfo}
            layout={AdminLayout}
          />
          <Route path={FORGETPASSWORD}>
            <Suspense fallback={<Loader />}>
              <ForgetPassword />
            </Suspense>
          </Route>
          <Route path={GENERATEPASSWORD}>
            <Suspense fallback={<Loader />}>
              <GeneratePassword />
            </Suspense>
          </Route>
          <Route
            exact
            path="/"
            render={(properties) => (
              <Suspense fallback={<Loader />}>
                <Login {...properties} />
              </Suspense>
            )}
          />
          <Route path={PERMISSIONERROR} component={permissionError} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};
export default AppRoutes;
