import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import _ from 'lodash';
import jwt from 'jsonwebtoken';
import { userAuthenticate } from '../utils/api/url-helper';
import { FAILED_TO_LOAD } from '../settings/constants';

export const AuthContext = React.createContext({});

const { REACT_APP_JWT_SECRET } = process.env;

const isValidToken = () => {
  const token = localStorage.getItem('token');
  if (token) return true;
  return false;
};

const AuthProvider = (properties) => {
  const history = useHistory();
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const [loading, setLoading] = useState(false);
  const authenticate = (values) => {
    setLoading(true);
    userAuthenticate(values)
      .then((response) => {
        const { success, data, error } = response;
        if (success === false) throw new Error(error);
        localStorage.setItem('token', data);
        const decryptData = jwt.verify(data, REACT_APP_JWT_SECRET);
        makeAuthenticated(true);
        setLoading(false);
        if (decryptData.RoleName === 'super_admin') history.push('/user-info');
        if (decryptData.RoleName === 'app_user')
          history.push(`/user-info/${decryptData.UserID}`);
        if (_.get(decryptData, 'isQuote')) history.push('/quote-info');
        if (_.get(decryptData, 'isLeadTime')) history.push('/lead-time-info');
        if (_.get(decryptData, 'isSearch')) history.push('/search-info');
      })
      .catch((errors) => {
        setLoading(false);
        notification.error({
          message: _.get(errors, 'error' || FAILED_TO_LOAD),
        });
      });
  };
  const signout = () => {
    makeAuthenticated(false);
    localStorage.removeItem('token');
  };
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        loading,
      }}
    >
      <>{properties.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
