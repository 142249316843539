// **************** ROUTE CONSTANT START **************************
// General Page Section
export const USERINFO = '/user-info';
export const USERINFOID = '/user-info/:id';
export const KEYINFOID = '/key-info/:id';
export const COMPANYKEYINFO = '/company-info';
export const ASNKEYINFO = '/asn-info';
export const SEARCHINFO = '/search-info';
export const QUOTEINFO = '/quote-info';
export const LTINFO = '/lead-time-info';
export const FORGETPASSWORD = '/forgot-password';
export const GENERATEPASSWORD = '/GeneratePassword/:token';
export const PERMISSIONERROR = '/permission-error';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';

//* **************Notification Sections******************* */

// user message
export const USER_ADD_SUCCESS = 'User Created successfully';
export const USER_ADD_FAILED = 'Failed to create the user';
export const USER_UPDATE_SUCCESS = 'User Updated successfully';
export const USER_UPDATE_FAILED = 'Failed to update the user';
export const USER_DELETE_SUCCESS = 'User Deleted successfully';
export const USER_DELETE_FAILED = 'Failed to delete the user';
export const USER_UPDATE_PASSWORD_FAILED = 'Failed to update the password';
export const USER_UPDATE_PASSWORD_RESPONSE_SENT =
  'We have e-mailed your password reset link!';
export const USER_UPDATE_PASSWORD_SUCCESS = 'Your password has been reset!';

// General error
export const FAILED_TO_LOAD = ' Failed to load the data';
export const DOWNLOAD_FAILED =
  'The download was unable to complete.Please try again later.';

// upload error
export const UPLOAD_EMPTY = 'Upload file must not be empty';
export const UPLOAD_GREATER_THAN_100 = 'Upload file must not exceed 100 parts';
export const UPLOAD_GREATER_THAN_500 = 'Upload file must not exceed 500 parts';
export const UPLOAD_GREATER_THAN_1000 =
  'Upload file must not exceed 1000 parts';
// Upload status//
export const UPLOAD_FAILED = 'Unable to upload the data';
export const UPLOAD_SUCCESS = 'File uploaded successfully.';
export const REFRESH_SUCCESS = 'Data Refreshed successfully.';
export const REFRESH_FAILED = 'Failed to refresh the data';
export const FILE_DELETE_SUCCESS = 'File deleted successfully';
export const FILE_DELETE_FAILED = 'Unable to delete the file';
