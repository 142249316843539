import React from 'react';

const permissionError = () => {
  return (
    <div className="error-container">
      <div>
        <h1>403</h1>
        <h2>
          We are sorry but you do not have permission to access this page...
        </h2>
      </div>
    </div>
  );
};

export default permissionError;
