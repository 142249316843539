import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import withClearCache from './clear-cache';
import AppRoutes from './app-routes';
import * as serviceWorker from './serviceWorker';

const ClearCacheComponent = withClearCache(AppRoutes);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ClearCacheComponent />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
